
<template>
    <div class="trackit">
        <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
        <van-form class="sale_form" >
            <van-row type="flex" class="border_bottom" justify="center">
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.game"
                        label="选择游戏"
                        placeholder="请选择"
                        :left-icon="form.gameicon"
                        @click="serverFn(0)"
                    />
                    <!-- form.gamePicker = true -->
                    <van-popup v-model="form.gamePicker" position="bottom" duration="0.15">
                        <van-picker
                            show-toolbar
                            value-key="channelName"
                            :columns="form.games"
                            @confirm="popConfirm"
                            @cancel="form.gamePicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.server"
                        label="选择区服"
                        placeholder="请选择"
                        :left-icon="form.servericon"
                        @click="serverFn(1)"
                    />
                    <van-popup v-model="form.serverPicker" position="bottom" duration="0.15">
                        <van-picker
                            show-toolbar
                            value-key="server_name"
                            :columns="form.servers"
                            @confirm="popConfirm"
                            @cancel="form.serverPicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.role"
                        label="选择角色"
                        placeholder="请选择"
                        :left-icon="form.roleicon"
                        @click="serverFn(2)"
                    />
                    <van-popup v-model="form.rolePicker" position="bottom" duration="0.15">
                        <van-picker
                            show-toolbar
                            value-key="chrname"
                            :columns="form.roles"
                            @confirm="popConfirm"
                            @cancel="form.rolePicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.genre"
                        label="遗失类型"
                        placeholder="请选择"
                        :left-icon="form.genreicon"
                        @click="form.genrePicker = true"
                    />
                    <!-- {{form.genrePicker}} -->
                    <van-popup v-model="form.genrePicker" position="bottom" duration="0.15">
                        <van-picker
                            show-toolbar
                            :columns="form.genres"
                            @confirm="popConfirm"
                            @cancel="form.genrePicker = false"
                        />
                    </van-popup>
                </van-col>
                <!-- <van-col span="21" style="border: 1px solid #efefef;">
                    <input class="good_text" type="text" @input="changeInput2" v-model="itemvalue" placeholder="请输入正确物品名称并选中">
                </van-col> -->
            </van-row>
            <van-row type="flex" class="border_bottom" justify="center">
                <van-col span="21" style="border: 1px solid #efefef;">
                    <!-- <van-field v-model="form.lost_goods" label="" placeholder="请填写需找回物品名称，多个则用“，”隔开(必填)" /> -->
                    <van-button class="outside_btn_finish" style="width:100%;" color="linear-gradient(to right, #FF704D, #F73727)" @click="addTutu">{{form.lost_goods}}</van-button>
                </van-col>
                <van-col span="21" style="border: 1px solid #efefef;">
                    <van-field v-model="form.remarks" label="" placeholder="遗失情况描述" />
                </van-col>
            </van-row>
            <div class="color_text orange">*简要描述遗失情况，可帮助工作人员更快进行问题处理</div>
            <router-link to="/retrieveTrackit"><div class="color_text blue">物品找回记录</div></router-link>
            <van-row type="flex" justify="center" class="van_rule">
                <van-col span="21" class="sumbit_btn">
                    <van-button @click="onbmit" round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)">提交找回</van-button>
                </van-col>
            </van-row>
        </van-form>
        <van-popup v-model="show" class="popupBg">
            <div class="addListbg">
                <van-row style="padding-bottom:10px">
                    <van-col span="24" class="red">*输入框支持模糊搜索，搜索出对应物品后选中填充。</van-col>
                </van-row>
                <van-row type="flex" justify="space-between" style="padding-bottom:14px" v-for="(item,index) in lost_goods" :key="item.id">
                    <van-col span="11" style="position:relative">
                        <input class="good_text" type="text" @blur="onMe(item,'blur', $event)" @focus="onMe(item,'focus', $event)" @input="changeInput(item)" v-model.trim="item.good_text" placeholder="请输入正确物品名称并选中">
                        <ul class="good_list" v-if="item.good_text.length!==0&&item.isTrue&&item.item_list.length>0" >
                            <li v-for="items in item.item_list" :key="items.id" @click="chooseThis(items,index)" >{{items.name}}</li>
                        </ul>
                    </van-col>
                    <van-col span="9" style="display: flex;align-items: center;"><van-stepper min="1" v-model="item.value" button-size="38px" integer /></van-col>
                    <van-col span="3"><van-button :disabled="lost_goods.length==1" @click="delList(index)" class="del_btn" type="danger">删除</van-button></van-col>
                </van-row>
                <van-row type="flex"  justify="space-between" style="padding-bottom:24px">
                    <van-col span="24"><van-button v-if="lost_goods.length!=8" style="height: 34px;" class="del_btn" @click="addList()" plain type="info">+</van-button></van-col>
                    
                </van-row>
                <van-row type="flex" justify="space-around" style="padding-bottom: 27px;">
                    <van-col span="6"><van-button class="finish_btn" @click="finishFn()"   type="info">填写完毕</van-button></van-col>
                </van-row>
            </div>
        </van-popup>
        <!-- </van-pull-refresh> -->
    </div>
</template>
<script>
import {debounce, throttle} from "@/assets/js/utils.js";
export default {
    name: 'Trackit',
    data() { 
        return {
            isLoading:false,
            itemvalue: '',
            active: 0,
            value:'',
            form: {
                game: '',
                games: [
                    {
                        text: '仙境传奇',
                        id: '1001'
                    }
                ],
                channel_id:'',
                gamePicker: false,
                gameicon: require('../../assets/images/sale_icon1.png'),
                server: '',
                servericon: require('../../assets/images/sale_icon2.png'),
                serverPicker: false,
                servers: [
                    {
                        text: '仙境001',
                        id: '1001'
                    },{
                        text: '仙境002',
                        id: '1002'
                    },{
                        text: '仙境003',
                        id: '1003'
                    }
                ],
                role: '',
                roleicon: require('../../assets/images/sale_icon3.png'),
                rolePicker: false,
                roles: [
                    {
                        text: '战士',
                        id: '1001'
                    },{
                        text: '法师',
                        id: '1002'
                    },{
                        text: '道士',
                        id: '1003'
                    }
                ],
                genre: '',
                genreicon: require('../../assets/images/sale_icon5.png'),
                genrePicker: false,
                genres: [],
                lost_goods:'点击填选物品及对应数量',
                remarks:''
            },
            lost_goods:[{
                value:1,
                good_text:'',
                isTrue:false,
                item_list:[]
            }],
            upData:{},
            show:false,
            flag:false,
            // isTrue:false
            click: '',
            isClick:true,
            lost_goods_json:[],
            // but_text:'点击填选物品及对应数量',
            chooseVal:{},
            debounce

            
        }
    },
    created(){
        if(!localStorage.getItem('token')){
            return false;
        }
        let data = {
            onsale_type:1 
        }
        this.$api.helper.getChannel(data).then((res)=>{
            if (sessionStorage.getItem("type")==='24') {
                let arr_item = []
                res.data.forEach((item,index)=>{
                if(item.channelId==24){
                    // console.log(res.data[index])
                    // res.data = []
                    // res.data.push(res.data[index])
                    arr_item.push(res.data[index])
                }
                })
                res.data = arr_item
                // console.log(res.data)
            }else{
                res.data.forEach((item,index)=>{
                if(item.channelId==24){
                    res.data.splice(index,1)
                }
                })
            }
            this.form.games = res.data;
            // if( JSON.stringify(this.$route.params) !== "{}"){
            if( false){
                let obj = this.$route.params;
                // console.log(obj)
                this.form.remarks = obj.remarks;
                if(obj.lost_goods!=''){
                    this.form.lost_goods = obj.lost_goods;
                    let goods_arr = this.form.lost_goods.split('、');
                    this.lost_goods = [];
                    goods_arr.forEach((item,index,arr)=>{
                        this.lost_goods.push({
                            value:item.split('*')[1],
                            good_text:item.split('*')[0],
                            isTrue:false,
                            item_list:[]
                        })
                    })
                }
                this.form.games.forEach(item=>{
                    if(item.channelId==obj.channel_id){
                        let data = {
                            channel_id:obj.channel_id
                        }
                        this.upData.channel_id = obj.channel_id;
                        this.upData.server_id = obj.server_id;
                        // 
                        this.form.channel_id = obj.channel_id;
                        // 获取区服
                        this.$api.helper.getServerList(data).then((res)=>{
                            this.form.servers = res.data;
                            this.form.game = obj.channel_name;
                            this.form.servers.forEach(item=>{
                                if(item.server_id==obj.server_id){
                                    let data = {
                                        channel_id:this.form.channel_id,
                                        server_id:item.server_id
                                    }
                                    this.form.server = item.server_name;
                                    this.upData.regione_id = item.regione_id;
                                    // 获取角色数据
                                    this.$api.helper.getPlayerList(data).then((res)=>{
                                        this.form.roles = res.data;
                                        this.form.roles.forEach(item=>{
                                            if(item.seedname==obj.seedname){
                                                this.upData.seedname = item.seedname;
                                                this.upData.chrname = item.chrname;
                                                this.form.role = item.chrname;
                                            }
                                        })
                                    })
                                }
                            })
                        })
                    }
                })
            }
        })
        // 获取物品找回类型
        this.$api.helper.itemRecoveryTypeList().then((res)=>{
            res.data.map((item, index, array) => {
            　　this.form.genres.push({text:item,id:index});
            });
            // if( JSON.stringify(this.$route.params) !== "{}"){
            if( false){
                let obj = this.$route.params;
                this.upData.type = obj.id;
                this.form.genre = this.form.genres[obj.type].text;
            }
        });
        this.click = this.debounce(this.fangdou, 400);
    },
    mounted(){
        this.$nextTick(function () {
            window.addEventListener('resize', function () {
                if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
                window.setTimeout(function () {
                    document.activeElement.scrollIntoViewIfNeeded();
                    // console.log(document.activeElement)
                }, 0)
                }
            })
        })
    },
    methods: {
        onRefresh(){
            console.log(222)
            // this.isLoading = false
        },
        // 获得&失去焦点
        onMe(item,str){
            this.isClick = false;
            if(str=='blur'){
                setTimeout(()=>{
                    item.isTrue = false;
                },100)
            }else{
                if(item.item_list.length>0){
                    item.isTrue = true;
                    document.querySelector(".good_list").scrollIntoViewIfNeeded();
                }
            }
            
        },
        // 验证时候登录
        isToken(){
            let token = localStorage.getItem('token');
            if(!token){
                this.$router.push({
                    name: 'Login',
                });
            }
        },
        // 监听输入
        changeInput: function (val) {
            // console.log(val);
            this.chooseVal = val;
            this.chooseVal.item_list = [];
            this.click();
        },
        // 填写完毕
        finishFn(){
            // this.show = false;
            this.isClick = true;
            for(var i = 0;i<this.lost_goods.length;i++){
                if(this.lost_goods.length>1){
                    
                    if(this.lost_goods[i].good_text==''){
                        this.lost_goods.splice(i,1);
                        i = i - 1;
                    }
                    if(!this.lost_goods[i].typeId){
                        this.isClick = false;
                    }
                }else{
                    if(!this.lost_goods[0].typeId){
                        this.isClick = false;
                    }
                }
            }
            this.lost_goods.forEach((item, index, arr)=>{
                // console.log(item)
                // "name": "行会LV10", "count": "1", "typeId": "20000300"
                this.lost_goods_json.push({name:item.good_text,count:item.value,typeId:item.typeId})
                if(this.lost_goods[0].good_text!=''){
                    str = str+item.good_text+'*'+item.value+',';
                }else{
                    str = '点击填选物品及对应数量';
                }
                
            })
            if(!this.isClick){
                this.$toast({
                    message: '请选中模糊搜索时系统检测到的物品名称，须选中系统才能识别到对应物品',
                    duration: 2000
                });
                return false
            }
            
            let str = '';
            this.lost_goods_json = [];
            this.lost_goods.forEach((item, index, arr)=>{
                // console.log(item)
                // "name": "行会LV10", "count": "1", "typeId": "20000300"
                this.lost_goods_json.push({name:item.good_text,count:item.value,typeId:item.typeId})
                if(this.lost_goods[0].good_text!=''){
                    str = str+item.good_text+'*'+item.value+',';
                }else{
                    str = '点击填选物品及对应数量';
                }
                
            })
            // str.substr(-1, 1);
            this.form.lost_goods = str.substr(0, str.length-1);
            this.show = false;
        },
        fangdou() {
            let data = {
                goods_name:this.chooseVal.good_text,
                channel_id:this.upData.channel_id
            };
            this.clearHttpRequestingList();
            this.$api.helper.seachgoods(data).then((res)=>{
                if(res.code==200){
                    this.chooseVal.isTrue = true;
                    this.chooseVal.item_list = res.data;
                    // document.querySelector(".good_list").scrollIntoViewIfNeeded();
                    
                     this.$nextTick(function () {
                         document.querySelector(".good_list").scrollIntoViewIfNeeded();
                     })
                } 
            })
        },
        // 删除一行
        delList(index){
            this.lost_goods.splice(index,1);
        },
        // 添加一行
        addList(){
            let obj = {
                value:0,
                good_text:'',
                isTrue:false,
                item_list:[]
            };
            this.lost_goods.push(obj);
        },
        // 模糊搜索选择
        chooseThis(str,index){
            this.lost_goods[index].good_text = str.name;
            this.lost_goods[index].typeId = str.typeId;
        },
        // 模糊搜索弹窗
        addTutu(){
            this.isToken();
            if(!localStorage.getItem('token')){
                return false;
            }
            if(this.form.game){
                this.show = true;
            }else{
                this.$toast({
                    message: '请先选择游戏',
                    duration: 1500
                });

            }
            
            return false;
        },
        // 选择区服角色
        serverFn(nb){
            this.isToken();
            if(!localStorage.getItem('token')){
                return false;
            }
            if(nb==0){
                this.form.gamePicker = true;
            }else if(nb==1){
                // 选择区服
                if(this.form.game){
                    this.form.serverPicker = true;
                }else{
                    this.$toast({
                        message: '请先选择项目，再选区服',
                        duration: 1500
                    });

                }
            }else if(nb==2){
                // 选择角色
                if(this.form.server){
                    this.form.rolePicker = true;
                }else{
                    this.$toast({
                        message: '请先选择区服，再选角色',
                        duration: 1500
                    });

                }
            }
            
            
        },
        //tab切换
        tabsClick(name) {
            //console.log(name);
        },
        //返回一个特定的 DOM 节点，作为挂载的父节点,挂在气泡框
        getContainer() {
            return document.querySelector('.van_rule');
        },
        //选择游戏确认
        popConfirm(val, index) {
            let str;
            if(this.form.gamePicker){
                str = 'game';
                if(this.form.games.length==0){
                    this.form[str+'Picker'] = false;
                    return false;
                }
                let data = {
                    channel_id:val.channelId,
                }
                this.upData.channel_id = val.channelId;
                this.form.channel_id = val.channelId;
                this.$api.helper.getServerList(data).then((res)=>{
                    // this.form.servers = res.data;
                    this.form.servers = [];
                    for (var key in res.data) {
                        this.form.servers.push({
                            server_name: res.data[key].server_name,
                            server_id: res.data[key].server_id,
                            regione_id: res.data[key].regione_id,
                        });
                    }
                })
                // console.log(this.form.servers)
                this.form[str] = val.channelName;
                // 清空值
                this.form.server = '';
                this.form.role = '';
                this.form.genre = '';
                this.form.lost_goods = '点击填选物品及对应数量';
                this.form.remarks = '';
            }else if(this.form.serverPicker){
                str = 'server';
                if(this.form.servers.length==0){
                    this.form[str+'Picker'] = false;
                    return false;
                }
                let data = {
                    channel_id:this.form.channel_id,
                    server_id:val.server_id,
                    regione_id: val.regione_id,
                    type: 0
                }
                this.upData.server_id = val.server_id;
                this.upData.regione_id = val.regione_id;
                this.form[str] = val.server_name;
                this.$api.helper.getPlayerList(data).then((res)=>{
                    this.form.roles = res.data;
                })
                // 清空值
                this.form.role = '';
                this.form.genre = '';
                // this.form.lost_goods = '';
                this.form.remarks = '';
            }else if(this.form.rolePicker){
                str = 'role';
                if(this.form.roles.length==0){
                    this.form[str+'Picker'] = false;
                    return false;
                }
                this.upData.seedname = val.seedname;
                this.upData.server_id = val.server_id;
                this.upData.chrname = val.chrname;
                this.form[str] = val.chrname;
                // 清空值
                this.form.genre = '';
                // this.form.lost_goods = '';
                this.form.remarks = '';
            }else{
                str = 'genre';
                this.upData.type = val.id;
                this.form[str] = val.text;
                // 清空值
                // this.form.lost_goods = '';
                this.form.remarks = '';
            }
            this.form[str+'Picker'] = false;
        },
        //提交申请
        onbmit() {
            this.isToken();
            if(!localStorage.getItem('token')){
                return false;
            }
            if(!this.form.role){
                this.$toast({
                    message: '请选择角色',
                    duration: 1500
                });
            }else if(!this.form.genre){
                this.$toast({
                    message: '请选择遗失类型',
                    duration: 1500
                });
            }else if((this.form.lost_goods == '点击填选物品及对应数量') || (!this.form.lost_goods)){
                this.$toast({
                    message: '请填写找回物品',
                    duration: 1500
                });
            }else{
                this.upData.lost_goods =  JSON.stringify(this.lost_goods_json);
                this.upData.remarks =  this.form.remarks;
                this.upData.user_name = localStorage.getItem('username');
                this.$api.helper.addItemRecovery(this.upData).then((res)=>{
                    if(res.data.data == 0 || res.code !=200){
                        this.$toast({
                            message: res.msg,
                            duration: 1500
                        });
                    }else{
                        this.$toast({
                            message: '申请已提交',
                            duration: 1500
                        });
                        this.form.game = '';
                        this.form.server = '';
                        this.form.role = '';
                        this.form.genre = '';
                        this.form.lost_goods = '点击填选物品及对应数量';
                        this.form.remarks = '';
                        Object.keys(this.upData).forEach(key => this.upData[key] = '');
                        this.lost_goods = [{
                            value:1,
                            good_text:'',
                            isTrue:false,
                            item_list:[]
                        }]
                    }
                })
            }
        }
    },
    beforeDestroy() {
        this.debounce=null;//清除闭包
        // console.log('销毁了')
    }
}
</script>
<style scoped>
.popupBg{
    width:95%;
    padding:8px;
    text-align: left;
    border-radius:5px;
}
.trackit_err{
    border-color: red !important;
}
.sale_form{
    padding: 4% 0;
}
.sale_form .van-col{
    margin-bottom: 3%;
}
.sale_form .van-cell{
    background-color: #efefef;
    border-radius: 3px;
    padding: 10px 2% 10px 15px;
}
.sale_form .van-field__label{
    color: #313131;
}
.sale_form .empower .van-field__label{
    width: 13em;
}
.border_bottom{
    border-bottom: 1px solid #dedede;
    margin-bottom: 3%;
}
.color_text{
    font: .6875rem/1.46875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    width: 87.5%;
    margin: 0 auto;
    text-align:left;
}
.orange{
    color: #808080 !important;
    padding-top: .5625rem;
    /* font: ; */
}
.blue{
    color: #ff4e00;
    text-decoration: underline;
    padding-bottom: 2.1875rem;
}
.van_rule{
    padding-bottom: 2.8125rem;
}
.red{
    color: red !important;
    font: .6875rem/1.46875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
}
.del_btn{
    padding: 0;
    width: 100%;
    height: 100%;
}
.good_text{
    padding: 0;
    height: 100%;
    background: #f2f3f5;
    padding-left: 5px;
    display: block;
    width: 100%;
    padding: 11px 0 11px 5px;
    font-size: 14px;
}
.finish_btn{
    padding: 0 5px;
    height: 34px;
    margin-left: 11%;
}
.good_list{
    position: absolute;
    width: 100%;
    z-index: 30000;
    border: 1px solid #ccc;
    background: #fff;
    max-height: 94px;
    overflow-y: scroll;
}
.good_list>li{
    font: 0.7875rem/1.86875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    text-indent: 5px;
}
.addListbg{
    /* height: 166px; */
    /* max-height: 413px; */
    overflow-y: scroll;
}
.outside_btn_finish .van-button__text{
    width: 100%;
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow:hidden;
}
</style>
<style>
.trackit_err .van-field__label{
    color: red !important;
}  
</style>